var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "grey lighten-3", attrs: { id: "features" } },
    [
      _c("section-header", {
        attrs: {
          "sub-header": "Get started today",
          text: "Test-drive the Knowledge Creation Cloud"
        }
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                rounded: "",
                outlined: "",
                to: { name: "trial" },
                color: "secondary"
              }
            },
            [_vm._v("Request a Trial")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }